///include /assets/js/plugins/network-property-set.js
///include /assets/js/app/p/user.js

"use strict"

if($.ajaxSetup) {
    $.ajaxSetup({
        cache: navigator.userAgent.match(/msie/i) ? false : null,
    });
}

class SUser extends NetworkPropertySet {
    static get inst() {
        if(!this._inst) {
            this._inst = new SUser()
        }
        return this._inst
    }
    constructor() {
        super()
        /** @type {?{avatarUrl: ?string, company_name: ?string, email: string, person_name: ?string}} */
        this.stackContact = null
        /** @type {GET.Auth.id.output} */
        this.identityInfo = null

        this.user = User.inst
    }
    get networkPropertyHandlers() {
        return {
            stackContact: () => $.ajax(`/a/stack_contact/me`).then(
                result => {
                    result.street = result.address.split("\n")
                    return $.Deferred().resolve(result)
                },
                jqXHR => {
                    if(jqXHR.status == 404) {
                        return $.Deferred().resolve({
                            avatarUrl: null,
                            company_name: null,
                            email: null,
                            person_name: null,
                        })
                    } else {
                        return $.Deferred().resolve(null)
                    }
                }
            ),
            identityInfo: () => this.user.preloadSingle("identityInfo"),
        }
    }

    /**
     * @type {?string} eg. "customer"
     */
    get type() {
        return this.identityInfo && this.identityInfo.content.replace(/:.*/, "")
    }
}
